export const ChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"       
      width="30"       
      height="30"    
      version="1"
      viewBox="0 0 4096 4096"     >       
      <path  
        d="M25400 33263c-52-8-141-29-197-48-192-64-325-145-494-298-57-52-2697-2689-5865-5859-5211-5212-5767-5772-5822-5855-100-153-170-319-203-488-19-93-19-377 0-470 33-169 103-335 203-488 55-83 611-643 5822-5855 3168-3170 5808-5807 5865-5859 169-154 303-234 494-298 322-107 681-77 988 81 359 185 614 544 673 945 53 361-44 712-277 1004-22 28-2437 2447-5366 5378l-5326 5327 5326 5328c2929 2930 5344 5350 5366 5377 105 132 176 261 228 418 196 588-73 1246-624 1531-242 125-522 171-791 129z"         
        transform="matrix(.1 0 0 -.1 0 4096)"            
      ></path>     
    </svg>
  );
};

