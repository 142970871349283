import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../atoms";
import { Inputs } from "../atoms";
import { PasswordValidation, EmailValidation } from "../validations";
import logo from "../assets/img/1.png";
import { Navbar } from "../molecules/Navbar";

export const Login = () => {
  const [login, setLogin] = useState({ email: "", password: "" });
  const { email, password } = login;
  const [alerts, setAlerts] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const alerts = {
      email: EmailValidation(email),
      password: PasswordValidation(password),
    };
    setAlerts(alerts);
  };
  return (
    <>
      <Navbar title={"Iniciar sesión"} display={true} />
      <div className="min-h-screen bg-white p-1 flex flex-col justify-evenly items-center ">
        <div className="flex items-center">
          <img src={logo} width="250" alt="flappy-logo" />
        </div>
        <div className="grid gap-2 mb-2 w-full">
          <Inputs
            type="text"
            title="Ingrese una direccion de email válido en este campo"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.email}
          </div>
          <Inputs
            type="text"
            title="La contraseña debe contener al menos 12 caracteres, una letra minúscula, una letra mayúscula, un número y un caracter especial"
            id="password"
            name="password"
            placeholder="Contraseña"
            value={password}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.password}
          </div>
        </div>
        <Button text="LOGIN" type="submit" onClick={handleSubmit} />
        <Link className="text-blue-900" to="/reset-password">
          ¿Olvidó su contraseña?
        </Link>
      </div>
    </>
  );
};
