import { Inputs, Button } from "../atoms";
import { useState } from "react";
import { UserIcon } from "../assets";
import { useNavigate } from "react-router-dom";

export const UserProfile = () => {
  const [user, setUser] = useState({
    license: "",
    experience: "",
    company: "",
    ine: "",
    rfc: "",
    nss: "",
    licenceFile: "",
    psychophysicalExamination: "",
    email: "",
    phoneNumber: "",
    razonSocial: "",
    caat: "",
    domicilioF: "",
  });
  const {
    username,
    experience,
    ine,
    rfc,
    nss,
    licence,
    psychophysicalExamination,
    email,
    phoneNumber,
  } = user;

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
 

  const navigate = useNavigate();
  const nextPage = () => {
    navigate("/unit");
  };
  const prevPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className=" justify-evenly h-[90vh] p-10">
        <div className="grid mb-10">
          <div className="flex flex-row items-center">
            <div className="flex flex-col w-[80%] ">
              <Inputs
                className=" text-3xl  bg-transparent border-none "
                type="text"
                id="user"
                name="user"
                placeholder="Usuario"
                value={username}
                onChange={handleChange}
              />

              <div className="text-xs pl-3 mb-2">Miembro desde ...</div>
            </div>
            <UserIcon />
          </div>

          <select
            className="font-medium rounded-lg px-3 py-2.5 text-left inline-flex  c text-base mb-2 my-1 bg-gray-50 border border-gray-300"
            id="experience"
            name="experience"
            value={experience}
            onChange={handleChange}
            tabIndex="0"
          >
            <option value="">Experiencia</option>
            <option value="ninguna" tabIndex="0">
              Ninguna
            </option>
            <option value="5meses" tabIndex="0">
              1-5 Meses
            </option>
            <option value="11meses" tabIndex="0">
              6-11 Meses
            </option>
            <option value="2años" tabIndex="0">
              1-2 Años
            </option>
            <option value="mas2" tabIndex="0">
              2+ Años
            </option>
          </select>

          <Inputs
            className=" text-base mb-2 bg-gray-50"
            type="number"
            id="ine"
            name="ine"
            placeholder="INE"
            value={ine}
            onChange={handleChange}
          />

          <Inputs
            className=" text-base mb-2 bg-gray-50"
            type="text"
            id="rfc"
            name="rfc"
            placeholder="RFC"
            value={rfc}
            onChange={handleChange}
          />

          <Inputs
            className=" text-base mb-2 bg-gray-50"
            type="number"
            id="nss"
            name="nss"
            placeholder="NSS"
            value={nss}
            onChange={handleChange}
          />

          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-2 rounded-lg">
            <label htmlFor="SCT" className="w-[100%]">
              Examen psicofisico
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="psychophysicalExamination"
              name="psychophysicalExamination"
              value={psychophysicalExamination}
              onChange={handleChange}
            />
          </div>

          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-2 rounded-lg">
            <label htmlFor="SCT" className="w-[100%]">
              Licencia
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="licence"
              name="licence"
              value={licence}
              onChange={handleChange}
            />
          </div>
          <Inputs
            className=" text-base mb-2 bg-gray-50"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          <Inputs
            className=" text-base mb-2 bg-gray-50"
            type="number"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Telefono"
            value={phoneNumber}
            onChange={handleChange}
          />
        </div>

        <div className="flex justify-evenly ">
          <Button text="ATRAS" type="button" onClick={prevPage} />

          <Button text="SIGUIENTE" type="submit" onClick={nextPage} />
        </div>
      </div>
    </>
  );
};
