import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'checking', // authenticated checking
        uid: null,
        email: null,
        username: null,
        rfc: null,
        password: null,
        errorMessage: null
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated'
            state.uid = payload.uid
            state.email = payload.email
            state.username = payload.username
            state.rfc = payload.rfc
            state.password = payload.password
            state.errorMessage = null
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated'
            state.uid = null
            state.email = null
            state.username = null
            state.rfc = null
            state.password = null
            state.errorMessage = payload?.errorMessage
        },
    },
})

export const { login, logout } = authSlice.actions