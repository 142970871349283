import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Inputs, Logo } from "../atoms";

export const ResetPassword = () => {
  const [userInfo, setUserInfo] = useState({ email: "" });
  const { email } = userInfo;
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserInfo({
      [e.target.name]: e.target.value,
    });
  };
  
    const nextPage = () => {
      navigate("/reset-validation");
    };
    const prevPage = () => {
      navigate(-1);
    };

  const handleSubmit = async () => {
    try {

    } catch (error) {
      console.error("Error al enviar correo de restablecimiento:", error);
      alert("La dirección de correo electrónico no está registrada");
    }
  };

  
  return (
    <>
      <div className="flex flex-col justify-between p-10  h-[60vh]">
        <div className="">
          <h2 className=" font-semibold text-center pt-10 pb-10 text-gray-700 text-xl ">
            Enviaremos un codigo a tu email{" "}
          </h2>

          <div className="">
            <Inputs
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-evenly w-[100%]  ">
        <Link to="/login">
          <Button
            text="Atras"
            type="submit"
            className="w-[40%]"
            // onClick={handleSubmit}
          />{" "}
        </Link>
        <Link to="/restore-password">
          <Button
            text="Siguiente"
            type="submit"
            className="w-[40%]"
            // onClick={handleSubmit}
          />
        </Link>
      </div>

      <div className="w-full p-5  flex flex-row justify-evenly items-center ">
        <Button text="ATRAS" type="button" onClick={prevPage} />
    
          <Button text="Siguiente" onClick={nextPage} />
        
      </div>
    </>
  );
};