import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
// import { startSetTripInformation } from '../store/trip';
import logo from "../assets/img/LOGO VERT B.png";
import { AutocompleteInput } from "../atoms/AutocompleteInput";
import findPathBetweenStates from "./findPathBetweenStates";
import Modal from "react-modal";
import useIsMobile from "../hooks/IsMobile";

// import baseGeoJson from '../assets/red_vial/base.geojson';

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    position: "relative",
    width: "400px",
    maxWidth: "90vw",
  },
};

export const MapPage = () => {
  // const dispatch = useDispatch();
  // const { route } = useSelector( state => state.trip )
  const [map, setMap] = useState(null);
  // const [userLocation, setUserLocation] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [originState, setOriginState] = useState();
  const [destination, setDestination] = useState(null);
  const [destinationState, setDestinationState] = useState();

  const [routeInfo, setRouteInfo] = useState({
    origin: null,
    destination: null,
    time: null,
    km: null,
  });
  const [showModal, setShowModal] = useState(false);

  const [flags, setFlags] = useState({
    originComplete: false,
    destinationComplete: false,
    stateNotFound: false,
  });

  const inputOriginRef = useRef(null);
  const inputDestinationRef = useRef(null);
  const isMobile = useIsMobile();

  const federalCitiesPths = {
    baja_california: {
      insurgentes: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/baja_california/federal_ciudad_insurgentes.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      ensenada: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/baja_california/federal_ensenada.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      guerrero_negro: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/baja_california/federal_guerrero_negro.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tijuana: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/baja_california/federal_tijuana.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    sonora: {
      agua_prieta: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/sonora/federal_agua_prieta.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      hermosillo: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/sonora/federal_hermosillo.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      san_luis_rio_colorado: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/sonora/federal_san_luis_rio_colorado.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    campeche: {
      campeche: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/campeche/federal_campeche.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    chiapas: {
      tonala: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chiapas/federal_tonala.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tuxtla: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chiapas/federal_tuxtla.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    chihuahua: {
      chihuahua: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chihuahua/federal_chihuahua.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      cuauhtemoc: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chihuahua/federal_cuauhtemoc.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      hidalgo_del_parral: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chihuahua/federal_hidalgo_del_parral.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      janos: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/chihuahua/federal_janos.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    estado_de_mexico: {
      atlacomulco: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/estado_de_mexico/federal_atlacomulco.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      ciudad_mexico: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/estado_de_mexico/federal_ciudad_mexico.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      pachuca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/estado_de_mexico/federal_pachuca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      teotihuacan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/estado_de_mexico/federal_teotihuacan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      toluca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/estado_de_mexico/federal_toluca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    coahuila_de_zaragoza: {
      monclava: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/coahuila/federal_monclava.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      torreon: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/coahuila/federal_torreon.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    colima: {
      colima: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/colima/federal_colima.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      manzanillo: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/colima/federal_manzanillo.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    durango: {
      cuencame: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/durango/federal_cuencame.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      durango: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/durango/federal_durango.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      gomez_palacio: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/durango/federal_gomez_palacio.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      jose_guadalupe_aguilera: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/durango/federal_jose_guadalupe_aguilera.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    guanajuato: {
      acambaro: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guanajuato/federal_acambaro.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      salamanca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guanajuato/federal_salamanca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    guerrero: {
      acapulco: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guerrero/federal_acapulco.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      altamirano: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guerrero/federal_altamirano.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      chipancingo: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guerrero/federal_chipancingo.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      taxco: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guerrero/federal_taxco.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      teloloapan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/guerrero/federal_teloloapan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    jalisco: {
      guadalajara: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/jalisco/federal_guadalajara.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      puerto_vallarta: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/jalisco/federal_puerto_vallarta.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    michoacan: {
      morelia: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/michoacan/federal_morelia.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      nueva_italia: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/michoacan/federal_nueva_italia.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      patzcuaro: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/michoacan/federal_patzcuaro.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      piedad_cabadas: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/michoacan/federal_piedad_cabadas.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      sahuayo_morelos: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/michoacan/federal_sahuayo_morelos.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    morelos: {
      cuautla: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/morelos/federal_cuautla.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      cuernavaca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/morelos/federal_cuernavaca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    nayarit: {
      compostela: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nayarit/federal_compostela.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      jalcocotan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nayarit/federal_jalcocotan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      las_varas: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nayarit/federal_las_varas.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      ruiz: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nayarit/federal_ruiz.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tepic: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nayarit/federal_tepic.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    nuevo_leon: {
      apodaca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nuevo_leon/federal_apodaca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      cadereyta_jimenez: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nuevo_leon/federal_cadereyta_jimenez.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      monterrey: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nuevo_leon/federal_monterrey.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      san_jose_de_raices: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/nuevo_leon/federal_san_jose_de_raices.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    oaxaca: {
      huajuapan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/oaxaca/federal_huajuapan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      oaxaca: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/oaxaca/federal_oaxaca.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      salina_cruz: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/oaxaca/federal_salina_cruz.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tuxtepec: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/oaxaca/federal_tuxtepec.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    puebla: {
      el_empalme: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/puebla/federal_el_empalme.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      itzucar: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/puebla/federal_itzucar.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      puebla: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/puebla/federal_puebla.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tehuacan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/puebla/federal_tehuacan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    quintana_roo: {
      cancun: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/quintana_roo/federal_cancun.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    sinaloa: {
      mazatlan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/sinaloa/federal_mazatlan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      pericos: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/sinaloa/federal_pericos.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    tabasco: {
      villa_hermosa: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tabasco/federal_villa_hermosa.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    tamaulipas: {
      ciudad_victoria: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tamaulipas/federal_ciudad_victoria.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      matamoros: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tamaulipas/federal_matamoros.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      nuevo_laredo: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tamaulipas/federal_nuevo_laredo.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      reynosa: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tamaulipas/federal_reynosa.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tampico: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tamaulipas/federal_tampico.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    tlaxcala: {
      tetla: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tlaxcala/federal_tetla.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tlaxcala: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/tlaxcala/federal_tlaxcala.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    veracruz: {
      cordoba: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_cordoba.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      minatitlan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_minatitlan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      poza_rica: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_poza_rica.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      santa_cruz: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_santa_cruz.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tempoal_sanches: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_tempoal_sanches.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tihuatlan: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_tihuatlan.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tuxpam: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_tuxpam.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      veracruz: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_veracruz.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      xalapa: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/veracruz/federal_xalapa.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
    yucatan: {
      merida: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/yucatan/federal_merida.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
      tizimin: async function renderGeojsonInMap() {
        try {
          const agc = await import(
            "../assets/red_vial/federal/yucatan/federal_tizimin.geojson"
          );
          map.data.loadGeoJson(agc.default);
        } catch (error) {
          console.error(error);
        }
      },
    },
  };

  const getStateName = (place) => {
    const state_information = place?.address_components?.find(
      (element) => element?.types[0] === "administrative_area_level_1"
    );
    if (state_information == null) return;

    return state_information["long_name"];
  };

  const getGeojsonsPath = (state_name) => {
    let stateName;
    if (typeof state_name !== "string") {
      stateName = state_name[0]
        .toLowerCase()
        .trim()
        .replace(/ /g, "_")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    } else {
      stateName = state_name
        .toLowerCase()
        .trim()
        .replace(/ /g, "_")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }
    console.log("stateName", stateName);
    if (federalCitiesPths[stateName] == null) {
      console.log("stateName", stateName);
      alert("Este estado o ciudad no está disponible en este momento.");
      return;
    }
    const keies = Object.keys(federalCitiesPths[stateName]);
    keies.map((key) => {
      federalCitiesPths[stateName][key]();
    });
  };

  const handleOriginSelect = (place) => {
    new window.google.maps.Marker({
      position: place.geometry.location,
      map,
      title: "Origen",
    });
    map.setCenter(place.geometry.location);
    map.setZoom(7);
    setOrigin(place);
    console.log(place);
    setFlags({ ...flags, originComplete: true });
  };

  const handleDestinationSelect = (place) => {
    new window.google.maps.Marker({
      position: place.geometry.location,
      map,
      title: "Destino",
    });
    map.setCenter(place.geometry.location);
    map.setZoom(7);
    setDestination(place);
    console.log(place);
    setFlags({ ...flags, destinationComplete: true });
  };

  const calculateAndDisplayRoute = () => {
    if (!directionsService || !origin || !destination) return;

    const waypoints = {
      tijuanaToMonterrey: [
        { location: "Tijuana, México", stopover: true },
        { location: "Mexicali, México", stopover: false },
        { location: "Imuris, México", stopover: false },
        { location: "Cananea, México", stopover: false },
        { location: "Agua Prieta, México", stopover: false },
        { location: "Janos, México", stopover: false },
        { location: "Chihuahua, México", stopover: false },
        { location: "Monterrey, México", stopover: true },
      ],
    };

    const request = {
      origin: origin.geometry.location,
      destination: destination.geometry.location,
      travelMode: "DRIVING",
      region: "MX",
      waypoints:
        origin.formatted_address === "Tijuana, B.C., México" &&
        destination.formatted_address === "Monterrey, N.L., México"
          ? waypoints.tijuanaToMonterrey
          : null,
    };
    if (
      origin.formatted_address === "Tijuana, B.C., México" &&
      destination.formatted_address === "Monterrey, N.L., México"
    ) {
      directionsService.route(request, (response, status) => {
        console.log(response);
        if (status === "OK") {
          directionsRenderer.setDirections(response);

          const origin = response.routes[0].legs[0].start_address
            .split(",")
            .slice(1)
            .join(",")
            .trim();
          const destination = response.routes[0].legs[0].end_address
            .split(",")
            .slice(1)
            .join(",")
            .trim();
          console.log("duration" + response.routes[0].legs[1].duration.text);
          const duration = response.routes[0].legs[1].duration.text;

          const distance = response.routes[0].legs[1].distance.text;
          /* 
          segun lo que me devuelva la funcion de trazar camino, renderizar todos los estados que trae el array
        */
          setRouteInfo({ origin, destination, time: duration, km: distance });
        } else {
          console.error("Error al calcular la ruta:", status);
        }
      });
    } else {
      directionsService.route(request, (response, status) => {
        console.log(response);
        if (status === "OK") {
          directionsRenderer.setDirections(response);

          const origin = response.routes[0].legs[0].start_address
            .split(",")
            .slice(1)
            .join(",")
            .trim();
          const destination = response.routes[0].legs[0].end_address
            .split(",")
            .slice(1)
            .join(",")
            .trim();
          console.log("duration" + response.routes[0].legs[1].duration.text);
          const duration = response.routes[0].legs[0].duration.text;
          const distance = response.routes[0].legs[0].distance.text;
          /* 
          segun lo que me devuelva la funcion de trazar camino, renderizar todos los estados que trae el array
        */
          setRouteInfo({ origin, destination, time: duration, km: distance });
        } else {
          console.error("Error al calcular la ruta:", status);
        }
      });
    }
  };

  useEffect(() => {
    if (origin == null) return;
    const originStateName = getStateName(origin);
    setOriginState(originStateName);
    getGeojsonsPath(originStateName);
  }, [origin]);

  useEffect(() => {
    if (destination == null) return;
    const destinationStateName = getStateName(destination);
    setDestinationState(destinationStateName);
    getGeojsonsPath(destinationStateName);
    const neighborStates = findPathBetweenStates(
      originState,
      destinationStateName
    );
    console.log(neighborStates);
    neighborStates?.map((state) => getGeojsonsPath(state));
  }, [destination]);

  useEffect(() => {
    const newMap = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 23.6597, lng: -103.3496 },
      zoom: 6,
      streetViewControl: false,
      fullscreenControl: false,
    });

    /*    ESTA GENERANDO DEMASIADO CONSUMO DE RECURSOS AL INICIAR        */
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      map: newMap,
    });
    // const trafficLayer = new window.google.maps.TrafficLayer();

    /*     async function loadGeojson1() {
      try {
        ///------------------------ ESTATALES ---------------------------
        const agc = await import(
          "../assets/red_vial/estatal/state_aguascalientes.geojson"
        );
        newMap.data.loadGeoJson(agc.default);

        const stateBC = await import(
          "../assets/red_vial/estatal/state_bc.geojson"
        );
        newMap.data.loadGeoJson(stateBC.default);

        const stateBCS = await import(
          "../assets/red_vial/estatal/state_bcs.geojson"
        );
        newMap.data.loadGeoJson(stateBCS.default);

        const stateCampeche = await import(
          "../assets/red_vial/estatal/state_campeche.geojson"
        );
        newMap.data.loadGeoJson(stateCampeche.default);

        const stateCDMX = await import(
          "../assets/red_vial/estatal/state_cdmx.geojson"
        );
        newMap.data.loadGeoJson(stateCDMX.default);

        const stateChiapas = await import(
          "../assets/red_vial/estatal/state_chiapas.geojson"
        );
        newMap.data.loadGeoJson(stateChiapas.default);
      } catch (error) {
        console.log("Error 1 !!!");
      }
    } */

    setMap(newMap);
    setDirectionsService(directionsService);
    setDirectionsRenderer(directionsRenderer);
  }, []);

  useEffect(() => {
    if (origin && destination) {
      calculateAndDisplayRoute();
    }
  }, [origin, destination]);

  const handleResetTrip = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="relative">
        <img
          className="absolute  z-10  left-3 bottom-8"
          src={logo}
          width="80"
          alt="logo"
        />
        <div id="map" style={{ height: "100vh", width: "100%" }}></div>
        <div
          className={
            origin && destination
              ? "absolute top-2 left-2.5 mt-12 m-0 shadow-2xl bg-white pl-4 pr-4 py-2 rounded-2xl  h-[25%]"
              : flags.originComplete || flags.destinationComplete
              ? "absolute top-2 left-2.5 mt-12 m-0 shadow-2xl bg-white pl-4 pr-4 py-2 rounded-2xl  h-[20%]"
              : "absolute top-2 left-2.5 mt-12 m-0 shadow-2xl bg-white pl-4 pr-4 py-2 rounded-2xl  h-[15%]"
          }
        >
          <div className="grid gap-2">
            <div className="mt-1">
              <AutocompleteInput
                ref={inputOriginRef}
                id="origin"
                placeholder="Origen"
                onSelect={handleOriginSelect}
                title={
                  flags.originComplete
                    ? "Reinicie el viaje para volver a ingresar un origen"
                    : "Seleccione su Origen"
                }
                disabled={flags.originComplete}
                value={origin}
              />
            </div>
            <div>
              <AutocompleteInput
                ref={inputDestinationRef}
                id="destination"
                placeholder="Destino"
                onSelect={handleDestinationSelect}
                title={
                  flags.destinationComplete
                    ? "Reinicie el viaje para volver a ingresar un destino"
                    : "Seleccione su Destino"
                }
                disabled={flags.destinationComplete}
                value={destination}
              />
              {origin && destination && (
                <>
                  <p className="mt-2 font-bold text-blue-900">
                    ETA: <span className="font-bold">{routeInfo.time}</span>
                  </p>
                  <p className="font-bold text-blue-900">
                    Distancia: <span>{routeInfo.km}</span>
                  </p>
                </>
              )}
            </div>
            {(flags.originComplete || flags.destinationComplete) && (
              <>
                <button
                  onClick={() => setShowModal(true)}
                  className="bg-blue-500 mt-2 hover:bg-blue-700 ml-12 text-white w-3/5 font-bold py-2 px-4 rounded-full"
                >
                  Reiniciar Viaje
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        style={modalStyles}
        contentLabel="Confirmar Reinicio de viaje"
      >
        <h3 className="text-2xl font-bold mb-4">Confirmar Reinicio de viaje</h3>
        <p className="mb-6 text-gray-700">
          ¿Estas seguro que deseas reiniciar este viaje? <br />
          Los datos no guardados de este viaje se perderan
        </p>
        <div className="flex flex-row justify-between  space-x-4">
          <button
            onClick={() => {
              handleResetTrip();
              setShowModal(false);
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            Reiniciar Viaje
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </>
  );
};
