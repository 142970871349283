import { ChevronIcon } from "../assets";
import { BurgerMenu } from "./BurgerMenu";
import { useNavigate } from "react-router";

export const Navbar = ({ title, display }) => {
  const showNavBar = display ? {} : { display: "none" };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <nav className="flex m-3 items-center" style={showNavBar}>
      <div className=" w-1/6 flex justify-center">
        <button onClick={goBack}>
          <ChevronIcon />
        </button>
      </div>
      <h1 className="w-2/3 text-3xl text-center leading-loose font-bold text-blue-600">
        {title}
      </h1>
      <div className="w-1/6 flex justify-center">
        <BurgerMenu />
      </div>
    </nav>
  );
};
