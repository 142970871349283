import { useEffect, useState } from "react";
import { Button } from "../atoms";
import { Inputs } from "../atoms";
import {
  EmailValidation,
  PasswordValidation,
  RFCValidation,
  UserValidation,
} from "../validations";
import { useNavigate } from "react-router-dom";
import { startRegisterUserEmailPassword } from "../store/auth";
import { useDispatch } from "react-redux";
import { Navbar } from "../molecules/Navbar";

export const Register = () => {
  const dispatch = useDispatch();

  const [register, setRegister] = useState({
    username: "",
    password: "",
    email: "",
    rfc: "",
    repeatPassword: "",
  });

  const { username, password, email, rfc, repeatPassword } = register;
  const [alerts, setAlerts] = useState({
    username: "",
    password: "",
    email: "",
    rfc: "",
    repeatPassword: "",
  });

  const handleChange = (e) => {
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
    });
    const hasAlerts = Object.values(alerts).some((valor) => valor !== "");
    console.log(hasAlerts);
    if (hasAlerts) {
      validateRegister();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const alertActive = Object.values(alerts).some((alert) => alert !== "");

    if (!alertActive) {
      try {
        dispatch(startRegisterUserEmailPassword(register));
        // const newUserCredential = await firebase
        //   .auth()
        //   .createUserWithEmailAndPassword(email, password);
        // const user = newUserCredential.user;
        // console.log(user.uid);

        // await firebase.database().ref(`users/${user.uid}`).set({
        //   username,
        //   email,
        //   rfc,
        // });

        // console.log("Usuario registrado exitosamente");
      } catch (error) {
        console.error("Error al registrar el usuario", error.message);
      }
    } else {
      console.log("Corrija los campos seleccionados");
    }
  };

  const navigate = useNavigate();

  const validateRegister = () => {
    const newAlerts = {
      username: UserValidation(register.username),
      password: PasswordValidation(register.password),
      email: EmailValidation(register.email),
      rfc: RFCValidation(register.rfc),
      repeatPassword:
        register.repeatPassword === ""
          ? "Este campo es obligatorio"
          : register.password !== register.repeatPassword
          ? "Las contraseñas deben coincidir"
          : "",
    };
    console.log(newAlerts);
    setAlerts(newAlerts);
    const hasAlerts = Object.values(newAlerts).some(
      (alert) => alert.trim() !== ""
    );

    return !hasAlerts;
  };

  const handleRegister = () => {
    if (validateRegister()) {
      navigate("/register-validation");
    }
  };

  return (
    <>
      <Navbar title={"Registro"} display={true} />
      <div className="flex flex-col justify-evenly h-[90vh] p-10">
        <div className="grid gap-2 mb-2">
          <Inputs
            type="text"
            title="Ingrese el código alfanumerico de 12 a 13 carcteres"
            id="rfc"
            name="rfc"
            placeholder="RFC"
            value={rfc}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.rfc}
          </div>
          <Inputs
            type="email"
            title="Ingrese una direccion de email válido en este campo"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.email}
          </div>
          <Inputs
            type="text"
            title="El nombre de usuario debe contener al menos 4 caracteres"
            id="username"
            name="username"
            placeholder="Nombre de usuario"
            value={username}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.username}
          </div>
          <Inputs
            type="password"
            title="La contraseña debe contener al menos 12 caracteres, una letra minúscula, una letra mayúscula, un número y un caracter especial"
            id="password"
            name="password"
            placeholder="Contraseña"
            value={password}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.password}
          </div>
          <Inputs
            type="password"
            title="Repita la contraseña establecida"
            id="repeatPassword"
            name="repeatPassword"
            placeholder="Repetir contraseña"
            value={repeatPassword}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-2 text-sm">
            {alerts.repeatPassword}
          </div>
        </div>
        <div className="flex justify-evenly container">
          <Button text="SIGUIENTE" type="submit" onClick={handleRegister} />
        </div>
      </div>
    </>
  );
};
