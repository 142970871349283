import { useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import React, { Link } from "react-router-dom";

export const Settings = () => {

     const navigate = useNavigate();
     const nextPage = () => {
       navigate("/trip");
     };
     const prevPage = () => {
       navigate(-1);
     };


  return (
    <>
      <div className="flex flex-col items-center my-[40%]">
        <Link to="/#" className="m-5 font-semibold text-gray-700 text-xl ">
          Mapa
        </Link>
        <Link to="/#" className="m-5 font-semibold text-gray-700 text-xl ">
          Operador
        </Link>
        <Link to="/#" className="m-5 font-semibold text-gray-700 text-xl ">
          Superadmin
        </Link>
      </div>
      <div className="flex justify-evenly ">
        <Button text="ATRAS" type="button" onClick={prevPage} />

        <Button text="SIGUIENTE" type="submit" onClick={nextPage} />
      </div>
    </>
  );
};
