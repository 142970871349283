import { useState } from "react";
import { BurgerMenuIcon, CloseIcon } from "../assets";
import { Link } from "react-router-dom";

export const BurgerMenu = () => {

  const [showMenu, setShowMenu] = useState(false);
  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <div className="">
      <BurgerMenuIcon
        onClick={() => setShowMenu(!showMenu)}
        className="absolute right-0 mx-auto "
      />
      {showMenu ? (
        <div className="fixed inset-0 w-full z-50 bg-blue-300 bg-opacity-50  backdrop-blur-sm">
          <CloseIcon
            onClick={() => setShowMenu(false)}
            className="cursor-pointer m-5 "
          />
          <div className="flex flex-col items-center my-[40%]">
            <Link
              to="/trip"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Mapa
            </Link>
            <Link
              to="/user"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Perfil de Operador
            </Link>
            <Link
              to="/unit"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Unidad de transporte
            </Link>
            <Link
              to="/company"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Compañia
            </Link>
            <Link
              to="/about"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Acerca de{" "}
            </Link>
            <Link
              to="/settings"
              onClick={handleLinkClick}
              className="m-5 font-semibold text-gray-700 text-xl "
            >
              Configuracion
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};
