import { useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import { useLocation } from "react-router-dom";

export const ResumeTrip = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { route } = location.state;

  const nextPage = () => {
    navigate("/reset-validation");
  };
  const prevPage = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="text-wrap p-3 text-2xl h-[70vh] text-blue-600">
        <p>
          Origen: <span>{route.origin}</span>
        </p>
        <p>
          Destino: <span>{route.destination}</span>
        </p>
        {/* <p>Ruta: <span>{null}</span></p> */}
        <p>
          Tiempo: <span>{route.time}</span>
        </p>
        <p>
          Distancia: <span>{route.km}</span>
        </p>
      </div>
      <div className="w-full p-5  flex flex-row justify-evenly items-center ">
        <Button text="ATRAS" type="button" onClick={prevPage} />

        <Button text="Siguiente" onClick={nextPage} />
      </div>
    </>
  );
};
