

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="60"
      fill="none"
      viewBox="0 0 78 75"
    >
      <path
        fill="#1B4576"
        fillOpacity="0.62"
        fillRule="evenodd"
        d="M38.875 75C17.405 75 0 58.21 0 37.5S17.405 0 38.875 0 77.75 16.79 77.75 37.5 60.345 75 38.875 75zm26.15-20.03c3.567-4.958 5.657-10.978 5.657-17.47 0-16.945-14.24-30.682-31.807-30.682-17.566 0-31.807 13.737-31.807 30.682 0 6.492 2.09 12.512 5.657 17.47 3.977-5.067 13.323-7.243 26.15-7.243 12.827 0 22.173 2.176 26.15 7.243zm-5.07 5.507c-1.035-3.588-8.465-5.932-21.08-5.932-12.614 0-20.045 2.344-21.08 5.931 5.612 4.795 12.994 7.706 21.08 7.706 8.086 0 15.468-2.911 21.08-7.706zm-21.08-12.75c-7.913 0-14.136-5.361-14.136-17.045 0-7.651 5.58-13.637 14.136-13.637 8.532 0 14.136 6.551 14.136 14.319 0 11.18-6.297 16.363-14.136 16.363zm-7.068-17.045c0 7.736 2.891 10.227 7.068 10.227 4.162 0 7.068-2.392 7.068-9.545 0-4.26-2.772-7.5-7.068-7.5-4.475 0-7.068 2.78-7.068 6.818z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
