import { createSlice } from "@reduxjs/toolkit";

export const tripSlice = createSlice({
    name: 'trip',
    initialState: {
        status: 'empty', // start-trip end-trip
        uid: null,
        operatorId: null,
        origin: "", //"CLL FRANCISCO I MADERO 365, CENTRO , SAN LUIS POTOSI",//"Cl. 157 #154-215, Floridablanca, Santander",
        destination: "", // "PROLONGACION MADERO 3811 OTE, FIERRO , MONTERREY",//"CLL PEDRO VALLEJO 1705, MONTECILLO , SAN LUIS POTOSI", // "Cl. 37 #10-36, García Rovira, Bucaramanga, Santander",
        route: {
            origin: null,
            destination: null,
            km: null,
            time: null,
        }
    },
    reducers: {
        setRouteInformation: ( state, { payload } ) => {
            state.route.origin = payload.origin
            state.route.destination = payload.destination
            state.route.km = payload.km
            state.route.time = payload.time
        },
        cleanRouteInformation: ( state ) => {
            state.route.origin = null
            state.route.destination = null
            state.route.km = null
            state.route.time = null
        },
        setTripInformation: ( state, { payload } ) => {
            state.status = "start-trip"
            state.origin = payload.origin
            state.destination = payload.destination
        },
        cleanTripInformation: ( state ) => {
            state.status = "empty"
            state.origin = ""
            state.destination = ""
            state.route.km = ""
            state.route.time = ""
        },        
    },
})

export const { setRouteInformation, setTripInformation, cleanTripInformation, cleanRouteInformation } = tripSlice.actions