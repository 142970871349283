import { forwardRef, useEffect, useRef, useState } from "react";

export const AutocompleteInput = forwardRef(
  ({ id, placeholder, onSelect, title, disabled = false }, ref) => {
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      if (
        !isLoaded &&
        window.google &&
        window.google.maps &&
        window.google.maps.places
      ) {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current
        );
        setIsLoaded(true);
      }

      const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        onSelect(place);
      };

      autocompleteRef.current.addListener("place_changed", handlePlaceChanged);

      return () => {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      };
    }, [onSelect, isLoaded]);

    // useEffect(() => {
    //     if (ref) {
    //         ref.current = inputRef.current;
    //     }
    // }, [ref]);

    return (
      <input
        className="bg-gray-10 border border-gray-300 rounded-lg w-5/4 p-3 text-base"
        ref={inputRef}
        id={id}
        placeholder={placeholder}
        title={title}
        disabled={disabled}
        type="text"
      />
    );
  }
);
