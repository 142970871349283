import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  Login,
  Register,
  ResetPassword,
  Company,
  TransportUnit,
  About,
  UserProfile,
  ResetPasswordValidation,
  //RegisterValidation,
  RestorePassword,
  Home,
  Settings,
  RegisterValidation,
  MapPage,
  Trip
} from "../pages";
import { Navbar, Footer } from "../molecules";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { ResumeTrip } from "../pages/ResumeTrip";

export const AppRouter = () => {
  //location es para la url, asi mostramos lo que hay en el navbar
  const location = useLocation();
  const thisLocation = useMemo(
    () => ({
      "/": false,
      "/home": false,
      "/login": "Login",
      "/footer": false,
      "/settings": "Configuración",
      "/register": "Registro",
      "/company": "Compañia",
      "/unit": "Unidad",
      "/about": "Acerca de",
      "/user": "Perfil",
      "/password-validation": "Validacion de contraseña",
      "/restore-password": "Restablecer contraseña",
      "/recover-password": "Recuperar contraseña",
      "/reset-password": "Recuperar contraseña",
      "/reset-validation": "Validar contraseña",
      "/register-validation": "Validar registro",
      "/resume-trip":"Resumen",
      "/map": "Mapa"

    }),
    []
  );
  const [display, setDisplay] = useState(false);
    const [displayFoter, setDisplayFooter] = useState(false);
  useEffect(() => {
    if (!thisLocation[location.pathname]) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [location, thisLocation]);



useEffect(() => {

  const footerLocations = [
    "/reset-password",
    "/reset-validation",
    "/restore-password",
    "/password-validation",
    "/user",
    "/register",
    "/company",
    "/unit",
    "/about",
  ];


  if (!footerLocations.includes(location.pathname)) {
    setDisplayFooter(false);
  } else {
    setDisplayFooter(true);
  }
}, [location]);



  return (
    <>
      {/* <Navbar title={thisLocation[location.pathname]} display={display} /> */}
      <Routes>
        <Route path="*" element={<MapPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/company" element={<Company />} />
        <Route path="/unit" element={<TransportUnit />} />
        <Route path="/about" element={<About />} />
        <Route path="/user" element={<UserProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-validation" element={<ResetPasswordValidation />} />
        <Route path="/restore-password" element={<RestorePassword />} />
        <Route path="/register-validation" element={<RegisterValidation />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/trip" element={<Trip />} />
        <Route path="/resume-trip" element={<ResumeTrip/>} />
      </Routes>
      {/* {display && <Footer />} */}
    </>
  );
};
