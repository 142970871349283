import { useState } from "react";
import { Button } from "../atoms";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../molecules/Navbar";

export const About = () => {
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const nextPage = () => {
    if (accepted) {
      navigate("/settings");
    } else {
      alert("Por favor, acepta los términos y condiciones");
    }
  };

  const prevPage = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar title={"Politica de privacidad"} display={true} />
      <div className="min-h-screen p-5" style={{ marginTop: "4rem" }}>
        <div
          style={{ maxWidth: "600px", maxHeight: "400px", overflowY: "auto" }}
        >
          <p className="text-justify">
            <br />
            <br />
            Aviso de Privacidad Flappy La presente política de privacidad
            detalla la información personal (en adelante, "datos personales")
            recopilada por Flappy LLC y sus subsidiarias (referidas en adelante
            como "Flappy", "nosotros", "nos" o "nuestro") sobre usted.
            Explicamos cómo utilizamos y protegemos sus datos personales, así
            como las opciones que tiene respecto al uso de los mismos según la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares (LFPDPPP). Valoramos su privacidad y la seguridad de su
            información personal, por lo que le recomendamos que se familiarice
            con nuestras políticas de privacidad y nos contacte si tiene alguna
            pregunta sobre cómo manejamos sus datos personales. 1. Responsable
            del Tratamiento de Datos Personales 1.1 Flappy LLC es responsable
            del tratamiento de los datos personales recopilados a través de
            Flappy. 2. Datos Personales Recopilados 2.1 La plataforma recopila
            datos personales necesarios para facilitar la comunicación y
            colaboración bidireccional entre la industria y los transportistas.
            Estos datos pueden incluir, entre otros: • Información de la
            industria: nombre, dirección, RFC, información de contacto,
            información de mercancías a transportar, ubicaciones de origen y
            destino de su mercancía, información de sus empleados como nombre,
            correo electrónico que usarán la plataforma, información sobre las
            personas y empresas destino que recibirán sus mercancías como
            nombre, dirección, RFC, información de contacto y empleados que
            reciban la mercancía como nombre y correo electrónico. El uso de
            estos datos tiene como finalidad informar y facilitar la
            comunicación con el transportista que la industria asigna para
            transportar sus mercancías desde los orígenes y a los destinos que
            la misma industria indica. • Información del transportista: nombre,
            dirección, RFC, información de contacto, información de sus unidades
            (# económico, placas, VIN, entre otros), operadores que operan sus
            unidades (nombre, edad, dirección, RFC, licencia, apto médico),
            rutas a seguir para transportar las mercancías asignadas por la
            industria, ubicación en tiempo real sobre sus unidades, información
            de sus empleados como nombre, correo electrónico que usaran la
            plataforma. El uso de estos datos tiene como finalidad informar y
            facilitar la comunicación con la industria la cual los asigna para
            transportar sus mercancías desde los orígenes y a los destinos que
            la misma industria indica. • Otros datos necesarios para cumplir con
            los servicios de transporte solicitado por la industria y asignado
            al transportista que la industria indica.3. Finalidades del
            Tratamiento de Datos 3.1 Los datos personales recopilados tienen
            como finalidad principal: • Facilitar la colaboración entre la
            industria y los transportistas. • Facilitar la información sobre los
            orígenes, destinos, rutas, mercancías, transportista, unidades y
            operadores asignados por la industria y el transportista según
            corresponde. • Trazabilidad y seguimiento de los embarques; asi como
            la documentación y pruebas de entrega. • Mejorar y personalizar la
            experiencia del usuario en la plataforma tanto de la industria como
            del transportista. 4. Consentimiento del Titular 4.1 Al utilizar
            Flappy, el usuario (ya sea industria o transportista) otorga su
            consentimiento expreso para el tratamiento de sus datos personales
            de acuerdo con los términos establecidos en este Aviso de
            Privacidad. 5. Derechos ARCO 5.1 Los derechos ARCO son un conjunto
            de derechos que protegen a los individuos en México en relación con
            sus datos personales, según lo establecido en la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares
            (LFPDPPP). La sigla ARCO proviene de los términos Acceso,
            Rectificación, Cancelación y Oposición. Estos derechos otorgan a los
            titulares de los datos ciertas facultades para controlar el uso y la
            protección de su información personal. • Acceso: El usuario tiene
            derecho a conocer qué datos personales suyos están siendo tratados
            por parte del responsable de los datos (tanto industria como
            transportista) y para qué fines se están utilizando, los cuales
            están detallados en este aviso de privacidad. • Rectificación: El
            usuario puede solicitar la corrección o actualización de sus datos
            personales si estos son inexactos, incompletos o están
            desactualizados. • Cancelación: El usuario tiene el derecho de
            solicitar la eliminación de sus datos personales de las bases de
            datos de Flappy LLC. Esto puede ocurrir, por ejemplo, cuando los
            datos ya no son necesarios para los fines para los que fueron
            recabados, o cuando el tratamiento de los mismos es contrario a lo
            establecido en la ley. • Oposición: El usuario puede oponerse al
            tratamiento de sus datos personales en ciertas situaciones
            específicas, como por ejemplo, en casos de mercadotecnia directa o
            cuando el tratamiento de los datos es para fines distintos a los
            originalmente consentidos en Flappy. 5.2 Ejercicio de los Derechos
            ARCO: Los titulares de los datos tienen derechos de acceso,
            rectificación, cancelación y oposición (derechos ARCO). Para ejercer
            estos derechos, el titular puede contactarnos a través de
            hola@myflappy.com.6. Tiempo de conservación de datos 6.1 Los datos
            personales se almacenan en nuestros servidores o en los de nuestros
            proveedores de servicios y otros socios, y están disponibles para
            empleados, representantes, agentes y proveedores de servicios
            autorizados que requieran acceso para los fines descritos en esta
            plataforma. Mantendremos los datos personales únicamente durante el
            tiempo necesario para cumplir el propósito para el cual fueron
            recopilados o para cumplir con los requisitos legales, normativos o
            de política interna aplicables. Para determinar el período de
            retención adecuado de los datos personales, consideramos la
            cantidad, naturaleza y sensibilidad de los mismos, el riesgo
            potencial derivado de un uso o divulgación no autorizados, los fines
            para los cuales se tratan y si estos fines pueden alcanzarse por
            otros medios, además de los requisitos legales pertinentes. 7
            Medidas de Seguridad 7.1 En Flappy, nos comprometemos a proteger la
            seguridad de los datos personales mediante la implementación de
            medidas contractuales, técnicas y organizativas diseñadas para
            prevenir el acceso, la comunicación y el uso no autorizados. Estas
            medidas se han establecido considerando la tecnología más avanzada
            disponible, su viabilidad de implementación y los riesgos asociados
            al tratamiento y la naturaleza de los datos personales involucrados.
            Al compartir datos personales con terceros, como proveedores de
            servicios, exigimos que estos mantengan la confidencialidad y
            seguridad de los datos que reciben. Sin embargo, a pesar de nuestros
            esfuerzos por garantizar la seguridad y confiabilidad de nuestros
            productos y servicios, no podemos asegurar la confidencialidad
            absoluta de todas las comunicaciones o materiales transmitidos o
            recibidos. Por tanto, le recomendamos precaución al enviar datos
            personales a través de Internet. Es importante tener en cuenta que
            cualquier información compartida en Internet podría ser
            potencialmente accesible al público y utilizada por terceros sin su
            consentimiento. Asimismo, el uso de Internet y otros medios de
            comunicación con nosotros es su responsabilidad exclusiva. 8.
            Transferencias de Datos Personales 8.1 En cumplimiento con la Ley
            Federal de Protección de Datos Personales en Posesión de
            Particulares (LFPDPPP) y las regulaciones del Instituto Nacional de
            Transparencia, Acceso a la Información y Protección de Datos
            Personales (INAI), nos comprometemos a respetar su privacidad y
            proteger sus datos personales. Es importante que comprenda cómo
            utilizamos y compartimos sus datos personales: Podemos compartir o
            transferir algunas categorías de datos personales, como se define en
            la LFPDPPP (Ley Federal de Protección de Datos Personales en
            Posesión de Particulares), con nuestros socios con el objetivo de
            comprender cómo utiliza los sitios web de Flappy o para fines de
            comercialización de servicios de Flappy. Si desea darse de baja de
            estas actividades, puedehacerlo enviando un correo a
            hola@myflappy.com para ejercer sus derechos ARCO. Durante los
            últimos doce meses, no hemos vendido ni compartido datos personales
            cubiertos por la LFPDPPP. Además, en ciertas circunstancias, podemos
            comunicar o transferir las categorías de datos personales
            mencionadas anteriormente, incluyendo: • A un comprador u otro
            sucesor en caso de fusión, adquisición, desinversión,
            reestructuración, reorganización, disolución u otra venta o
            transferencia de algunos o todos nuestros activos. • A nuestras
            empresas subsidiarias, filiales, agentes, contratistas, proveedores
            de servicios y otros socios con los que colaboramos, siempre que
            estén obligados por contrato a mantener la confidencialidad de los
            datos personales y utilizarlos únicamente para los fines acordados.
            • Para cumplir con cualquier orden judicial, ley o proceso legal,
            incluida la respuesta a solicitudes gubernamentales o regulatorias.
            • Para hacer cumplir o aplicar nuestros términos de servicio u otros
            acuerdos que rigen la venta, uso o compra de nuestros productos o
            servicios. • Para proteger los derechos, la propiedad o la seguridad
            de Flappy, nuestros empleados, nuestros usuarios u otros. Es
            importante tener en cuenta que algunos de nuestros productos y
            servicios pueden ofrecerse en colaboración con otros socios. Si se
            registra con un socio, Flappy también puede recibir sus datos
            personales y compartirlos con dichos socios en el contexto de estas
            ofertas conjuntas. Le recomendamos revisar detenidamente la política
            de privacidad de nuestro socio, ya que puede tener condiciones
            diferentes. 9. Cambios en el Aviso de Privacidad 9.1En Flappy, es
            posible que actualicemos nuestra política de privacidad de forma
            periódica para reflejar cambios en nuestras prácticas, tecnologías,
            requisitos legales y otros factores relevantes. Por este motivo, le
            recomendamos que visite esta página regularmente para consultar la
            versión más reciente y mantenerse al tanto de cómo se recopilan,
            tratan y comparten sus datos personales. Tenga en cuenta que, en
            caso de realizar cambios en nuestra política de privacidad,
            actualizaremos la fecha de "última actualización" al pie de la
            misma. Nos reservamos el derecho de realizar cambios en este Aviso
            de Privacidad. Los cambios se comunicarán a través de
            hola@myflappy.com y https://myflappy.com/ y entrarán en vigor
            después de su publicación. 10. Uso de Cookies y otras tecnologías
            10.1 En Flappy, utilizamos diversas tecnologías como cookies,
            etiquetas de píxel y kits de desarrollo de software (SDK), entre
            otras, para recopilar ciertos datos personales con diversos
            propósitos. Estos propósitos incluyen el análisis de tendencias, la
            gestión de nuestro sitio web y comunicaciones con los usuarios, el
            seguimiento del comportamiento de los usuarios en nuestro sitio web,
            y la recopilación de información sobre nuestra base de usuarios,
            como la ubicación através de direcciones IP, la visualización de
            publicidad en nuestro sitio web y la gestión de nuestra publicidad
            en otros sitios web. Las cookies, utilizadas comúnmente en la
            mayoría de los sitios web, son archivos de texto almacenados en el
            navegador del usuario que contienen información sobre el usuario,
            como detalles de los dispositivos y redes utilizados para acceder a
            nuestro sitio web. Las etiquetas de píxel, también conocidas como
            "balizas web" o "GIF invisibles", son imágenes pequeñas o fragmentos
            de datos incrustados en imágenes que pueden reconocer cookies, la
            hora y fecha de visualización de una página, así como otros detalles
            sobre la navegación del usuario. Los SDK son códigos informáticos
            que los desarrolladores de aplicaciones móviles pueden incorporar en
            sus aplicaciones para permitir la recopilación de datos y la
            implementación de funcionalidades relacionadas. Para obtener más
            información sobre las cookies que utilizamos en nuestro sitio web y
            servicios, así como para conocer cómo puede controlar nuestro uso de
            cookies y análisis de terceros, le recomendamos que lea y revise
            nuestra política de cookies. 11. Ley Aplicable y Jurisdicción 11.1
            Este Aviso de Privacidad se rige por las leyes de México. Cualquier
            disputa estará sujeta a la jurisdicción de los tribunales
            competentes en México. En México, la regulación de los avisos de
            privacidad y la protección de datos personales se rige
            principalmente por la Ley Federal de Protección de Datos Personales
            en Posesión de los Particulares (LFPDPPP), así como por diversas
            normativas y disposiciones complementarias. Las principales leyes,
            jurisdicciones e instituciones involucradas son: • Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares
            (LFPDPPP): Esta ley es la principal normativa que regula el
            tratamiento de los datos personales por parte de los particulares,
            estableciendo los principios, derechos y obligaciones que deben
            cumplir los responsables del tratamiento de datos personales. •
            Instituto Nacional de Transparencia, Acceso a la Información y
            Protección de Datos Personales (INAI): Es una institución autónoma
            encargada de promover y garantizar el derecho de acceso a la
            información pública, así como de proteger los datos personales en
            posesión de sujetos obligados y particulares. El INAI supervisa el
            cumplimiento de la LFPDPPP y puede imponer sanciones en caso de
            incumplimiento. • Secretaría de Economía: A través de la Dirección
            General de Normatividad Mercantil, esta secretaría es responsable de
            regular y supervisar el cumplimiento de las disposiciones relativas
            a la protección de datos personales en el ámbito empresarial. •
            Jurisdicción civil: Los conflictos relacionados con la privacidad y
            protección de datos personales pueden ser resueltos a través de
            procesos judiciales ante los tribunales civiles competentes. 11.2
            Cualquier controversia, disputa, conflicto o reclamación relacionada
            con este Aviso de Privacidad, incluida su existencia, validez,
            interpretación, ejecución, incumplimiento o terminación, así como
            las disputas relacionadas con su objeto o formación, será resuelta
            mediante arbitraje en México bajo las reglas de arbitraje de la
            Cámara de Comercio Internacional (CCI), que se regirán por las leyes
            de México y serán definitivas y vinculantes para las partes. 12.
            Contacto y Soporte 12.1 Si tiene alguna pregunta, comentario o
            inquietud sobre este Aviso de Privacidad o sobre nuestras prácticas
            de privacidad y seguridad de la información, no dude en contactarnos
            a través de hola@myflappy.com. Por favor, asegúrese de proporcionar
            toda la información relevante para que podamos atender su consulta
            de manera oportuna y eficiente. Nos comprometemos a responder a
            todas las consultas de forma profesional, confidencial y en el menor
            tiempo posible. 12.2 Este Aviso de Privacidad se actualizó por
            última vez el 22 de enero de 2024.
          </p>
          <br />
          <br />
        </div>
        <input
          type="checkbox"
          checked={accepted}
          onChange={handleCheckboxChange}
        />{" "}
        Acepto los términos y condiciones
      </div>
      <div className="flex justify-between mx-5 my-2">
        <Button onClick={prevPage}>Atrás</Button>
        <Button onClick={nextPage}>Aceptar</Button>
      </div>
    </>
  );
};
