const options = {
    camiones: "Camiones",
    dosEjes: "Camión 2 ejes",
    tresEjes: "Camión 3 ejes",
    cuatroEjes: "Camión 4 ejes",
    cincoEjes: "Camión 5 ejes",
    seisEjes: "Camión 6 ejes",
    sieteEjes: "Camión 7 ejes",
    ochoEjes: "Camión 8 ejes",
    nueveEjes: "Camión 9 ejes",
}

export const UnitType = ({ tipoUnidad, handleChange }) => {
  return (
    <select
        id="tipoUnidad"
        name="tipoUnidad"
        value={tipoUnidad}
        onChange={handleChange}
        tabIndex="0"
        className="font-medium rounded-lg px-3 py-2.5 text-left inline-flex text-base mb-3 my-2 bg-gray-50 border border-gray-300"
    >
        { Object.keys(options).map((key, index) => {
            const option = options[key]
            return (
                <option value={key} key={index} tabIndex={`${index}`}>
                    {option}
                </option>
            )
            
        })}
    </select>
  )
}
