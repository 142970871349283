import { useState } from "react";
import { Button, Inputs, UnitType } from "../atoms";
import { useNavigate } from "react-router-dom";

export const RegisterValidation = () => {
  const [register, setCode] = useState({ code: "", unitType: "" });
  const { code, unitType } = register;

  const handleChange = (e) => {
    setCode({
      ...register,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // console.log(code)
  };

  const navigate = useNavigate();
  const nextPage = () => {
    navigate("/trip");
  };
  const prevPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="flex flex-col justify-evenly h-[80vh] p-10">
        <div className="grid gap-2 mb-2">
          <h3 className="text-blue-500  font-bold text-xl">
            Verifica tu email e ingresa el codigo
          </h3>
          <Inputs
            type="code"
            id="code"
            name="code"
            placeholder=""
            value={code}
            onChange={handleChange}
          />
          <h3 className="text-blue-500  font-bold text-xl">Elige tu unidad</h3>
          <UnitType unitType={unitType} handleChange={handleChange} />
        </div>
      </div>
      <div className="w-full p-5  flex flex-row justify-evenly items-center ">
        <Button text="ATRAS" type="" onClick={prevPage}></Button>

        <Button text="VALIDAR" type="" onClick={nextPage}></Button>
      </div>
    </>
  );
};
