export default function validateUsername(username) {
  if (username) {
    if (username.length < 4) {
      return "El nombre de usuario debe tener al menos 4 caracteres";
    }
  } else {
    return "El nombre de usuario es obligatorio";
  }

  return "";
}

// async function userExists(userId) {
//   try{
//     const response = await fetch(`${apiURL}/${userId}}`)
//     if (!response.ok){
//       throw new Error("Usuario no encontrado");
//     }
//     const user = await response.json();
//     console.log('Usuario encontrado',user);
//   }catch (error){
//     console.log('Error: ', error);

//   }
// }
