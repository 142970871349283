export default function EmailValidation(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (email) {
    if (!regex.test(email)) {
      return "El email ingresado no es valido";
    }
  } else {
    return "El email es obligatorio";
  }

  return "";
}

// fetch('apiURL')
// .then(response => {
//   if (!response.ok){
//     throw new Error('Usuario no encontrado');
//   }
//   return response.json();
// })
// .then(data => console.log('Usuario encontrado: ',data))
// .catch((error) => {
//   console.log('Error: ',error);
// });
