import { useState } from "react";
import { Button, Inputs } from "../atoms";
import { useNavigate} from "react-router-dom";

export const ResetPasswordValidation = () => {

  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setCode(e.target.value);
  }

 const navigate = useNavigate();
 const nextPage = () => {
   navigate("/restore-password");
 };
 const prevPage = () => {
   navigate(-1);
 };

  return (
    <>
      <div className="flex flex-col justify-evenly h-[90vh] p-10">
        <div className="grid gap-2 mb-2">
          <h3 className="text-blue-500 text-center font-bold text-xl">
            Verifica tu email e ingresa el codigo
          </h3>
          <Inputs
            type="code"
            id="code"
            name="code"
            placeholder=""
            value={code}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-full p-5 flex flex-row justify-evenly  ">

        
          <Button text="ATRAS" type="button" onClick={prevPage} />

          <Button text="SIGUIENTE" type="submit" onClick={nextPage} />

      </div>
    </>
  );
}
