export default function validateRFC(rfc, userApiUrl) {
  let regexPerson = /^[A-ZÑ&]{4}[0-9]{6}[A-Z0-9]{3}$/;
  let regexCompany = /^[A-ZÑ&]{3}[0-9]{6}[A-Z0-9]{3}$/;

  if (rfc) {
    if (rfc.length === 13 && regexPerson.test(rfc)) {
      return "";
    } else if (rfc.length === 12 && regexCompany.test(rfc)) {
      return "";
    } else {
      return "El número RFC no es válido";
    }
  } else {
    return "El número RFC es obligatorio";
  }

  //USAR ESTE CODIGO PARA CUANDO TENGAMOS LA API

  // if (rfc.length === 13 && regexPerson.test(rfc)) {
  //   // El RFC es para una persona y es válido.
  //   //apiURL es para poner la url de la api que vayamos a usar
  //   try {
  //     const response = await fetch(`${apiURL}/${rfc}`);
  //     if (!response.ok){
  //       throw new Error('RFC no valido');
  //     }
  //   } catch(error){
  //     return error.message;
  //   }
  //   return "";

  // } else if (rfc.length === 12 && regexCompany.test(rfc)) {
  //   // El RFC es para una empresa y es válido
  //   try {
  //     const response = await fetch(`${apiURL}/${rfc}`);
  //     if (!response.ok){
  //       throw new Error("RFC no valido");
  //     }
  //   }catch (error){
  //     return error.message;
  //   }
  //   return "";
  // } else {

  //   return "El número RFC no es válido";
  // }
}
