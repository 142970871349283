export const Inputs = ({
  className,
  type,
  id,
  value,
  placeholder,
  name,
  onChange,
  title = "",
}) => {
  return (
    <>
      <input
        className={`${className} bg-gray-100 border border-gray-300 rounded-lg w-1/2 h-auto p-2 text-lg`}
        type={type}
        id={id}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        autoComplete="off"
        title={title}
      />
    </>
  );
};
