export default function validatePassword(password) {
  const regex = /[!@#$%]/;

  if (password) {
    if (password.length < 12) {
      return "La contraseña debe tener al menos 12 caracteres";
    } else if (!/[a-z]/.test(password)) {
      return "La contraseña debe tener al menos una letra minúscula";
    } else if (!/[A-Z]/.test(password)) {
      return "La contraseña debe tener al menos una letra mayúscula";
    } else if (!/[0-9]/.test(password)) {
      return "La contraseña debe tener al menos un número";
    } else if (!regex.test(password)) {
      return "La contraseña debe tener al menos un carácter especial: !@#$%";
    }
  } else {
    return "La contraseña es obligatoria";
  }

  return "";
}
