import { Inputs, Button, UnitType } from "../atoms";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


export const TransportUnit = () => {
  const [unit, setUnit] = useState({
    unitType: "",
    model: "",
    year: "",
    originBill: "",
    currentBill: "",
    circulationCard: "",
    plates: "",
    insurancePolicy: "",
    SCT: "",
  });
  const {
    unitType,
    model,
    year,
    currentBill,
    originBill,
    circulationCard,
    plates,
    insurancePolicy,
    SCT,
  } = unit;

  const handleChange = (e) => {
    setUnit({
      ...unit,
      [e.target.name]: e.target.value,
    });
  };

    const navigate = useNavigate();
    const nextPage = () => {
      navigate("/company");
    };
    const prevPage = () => {
      navigate(-1);
    };

  return (
    <>
      <div className="flex flex-col justify-evenly h-[90vh] p-10">
        <div className="grid mb-10">
          <UnitType unitType={unitType} handleChange={handleChange} />

          <Inputs
            className=" text-base mb-3 my-2 bg-gray-50"
            type="text"
            id="model"
            name="model"
            placeholder="Modelo"
            value={model}
            onChange={handleChange}
          />

          <Inputs
            className=" text-base mb-3 my-2 bg-gray-50"
            type="number"
            id="year"
            name="year"
            placeholder="Año"
            value={year}
            onChange={handleChange}
          />
          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-3 rounded-lg">
            <label htmlFor="originBill" className="w-[100%]">
              Factura de Origen
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="originBill"
              name="originBill"
              value={originBill}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-3 rounded-lg">
            <label htmlFor="currentBill" className="w-[100%]">
              Factura Actual
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="currentBill"
              name="currentBill"
              value={currentBill}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-3 rounded-lg">
            <label htmlFor="circulationCard" className="w-[100%]">
              Tarjeta de Circulacion
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="circulationCard"
              name="circulationCard"
              value={circulationCard}
              onChange={handleChange}
            />
          </div>

          <Inputs
            className=" text-base mb-3 my-2 bg-gray-50"
            type="text"
            id="plates"
            name="plates"
            placeholder="Plates"
            value={plates}
            onChange={handleChange}
          />
          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-3 rounded-lg">
            <label htmlFor="insurancePolicy" className="w-[100%]">
              Poliza de Seguro
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="insurancePolicy"
              name="insurancePolicy"
              value={insurancePolicy}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-between items-center bg-gray-50 border border-gray-300 pl-2 mb-3 rounded-lg">
            <label htmlFor="SCT" className="w-[100%]">
              SCT
            </label>
            <Inputs
              className="block w-[48%] text-sm rounded-lg bg-gray-50 dark:text-gray-400  p-0 border-none mr-2"
              type="file"
              id="SCT"
              name="SCT"
              placeholder="SCT"
              value={SCT}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex justify-evenly ">

            <Button
              text="ATRAS"
              type="button"
              onClick={prevPage}
            />

            <Button
              text="SIGUIENTE"
              type="button"
              onClick={nextPage}
            />

        </div>
      </div>
    </>
  );
};
