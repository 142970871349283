import { Link, useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import logo from "../assets/img/1.png";

export const Home = () => {
  const navigate = useNavigate();
  const nextPage = () => {
    navigate("/login");
  };

  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-gray-200 bg-cover bg-no-repeat "
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="rounded-xl bg-gray-800 bg-opacity-50 px-6 py-8 shadow-lg backdrop-blur-md max-sm:px-8 h-[95%] w-[95%] flex flex-col justify-between">
          <div className="text-gray-300 ">
            <div className="mb-36 flex flex-col  items-center">
              <img src={logo} width="250" alt="logo" />
              <h1 className="mb-4 text-4xl font-bold">Flappy</h1>
              <span className="text-gray-300 mt-4 text-2xl text-center">
                Simplificando el transporte de carga
              </span>
            </div>
            <div className="items-center flex flex-col ">
              <Button text="LOGIN" type="submit" onClick={nextPage} />
              <Link to="/register" className="text-gray-300 text-lg mt-8">
                ¿No tienes cuenta?{" "}
                <span className="text-blue-950 	font-weight: 700;">
                  Registrate
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
