import { Inputs, Button } from "../atoms";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../molecules/Navbar";

export const Company = () => {
  const [input, setInput] = useState({
    razonSocial: "",
    rfc: "",
    caat: "",
    domicilioFiscal: "",
    email: "",
    telefono: "",
    placas: "",
    polizaSeguro: "",
    SCT: "",
  });
  const { razonSocial, rfc, caat, domicilioFiscal, email, telefono } = input;

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const nextPage = () => {
    navigate("/about");
  };

  const prevPage = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar title={"Compañia"} display={true} />
      <div className="min-h-screen bg-white p-5">
        <div className="flex flex-col justify-between h-screen">
          <div>
            <h1 className="text-xl font-semibold mb-5">
              Información de la Compañía
            </h1>
            <div className="grid gap-5">
              <Inputs
                type="text"
                title="Ingrese el nombre de la razón social"
                id="razonSocial"
                name="razonSocial"
                placeholder="Razón Social"
                value={razonSocial}
                onChange={handleChange}
              />
              <Inputs
                type="text"
                title="Ingrese el código alfanumerico de 12 a 13 carcteres"
                id="rfc"
                name="rfc"
                placeholder="RFC"
                value={rfc}
                onChange={handleChange}
              />
              <Inputs
                type="text"
                title="Ingrese el código alfanumérico de transportista"
                id="caat"
                name="caat"
                placeholder="CAAT"
                value={caat}
                onChange={handleChange}
              />
              <Inputs
                type="text"
                title="Ingrese el domicilio fiscal"
                id="domicilioFiscal"
                name="domicilioFiscal"
                placeholder="Domicilio fiscal"
                value={domicilioFiscal}
                onChange={handleChange}
              />
              <h3 className="mt-5">Contacto</h3>
              <Inputs
                type="email"
                title="Ingrese una dirección de email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={handleChange}
              />
              <Inputs
                type="tel"
                title="Ingrese un número de teléfono"
                id="telefono"
                name="telefono"
                placeholder="Teléfono"
                value={telefono}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Button text="Atras" type="submit" onClick={prevPage} />
            <Button text="Siguiente" type="submit" onClick={nextPage} />
          </div>
        </div>
      </div>
    </>
  );
};
