

export const BurgerMenuIcon= ({onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_429_11066)">
        <path
          stroke="#292929"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 6h18M3 12h18M3 18h18"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_429_11066">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}


