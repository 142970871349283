const limitStates = {
    "aguascalientes": ["zacatecas", "jalisco", "san_luis_potosi"],
    "baja_california": ["California", "Arizona"],
    "baja_california-sur": ["California", "Arizona"],
    "campeche": ["yucatan", "quintana_roo", "tabasco"],
    "chiapas": ["tabasco", "veracruz", "oaxaca"],
    "chihuahua": ["sonora", "sinaloa", "durango", "coahuila", "nuevo_leon"],
    "coahuila": ["chihuahua", "durango", "zacatecas", "nuevo_leon", "tamaulipas"],
    "colima": ["jalisco"],
    "ciudad_de_mexico": ["estado_de_mexico", "morelos"],
    "durango": ["chihuahua", "sinaloa", "nayarit", "zacatecas", "coahuila"],
    "estado_de_mexico": ["hidalgo", "queretaro", "michoacan", "guerrero", "morelos", "ciudad_de_mexico", "tlaxcala", "puebla"],
    "guanajuato": ["zacatecas", "san_luis_potosi", "queretaro", "michoacan", "jalisco"],
    "guerrero": ["michoacan", "estado_de_mexico", "morelos", "puebla", "oaxaca"],
    "hidalgo": ["san_luis_potosi", "veracruz", "puebla", "estado_de_mexico", "queretaro", "tlaxcala"],
    "jalisco": ["colima", "michoacan", "guanajuato", "san_luis_potosi", "zacatecas", "nayarit"],
    "michoacan": ["guanajuato", "estado_de_mexico", "guerrero", "colima", "jalisco"],
    "morelos": ["estado_de_mexico", "guerrero", "ciudad_de_mexico", "puebla"],
    "nayarit": ["sinaloa", "durango", "jalisco"],
    "nuevo_leon": ["coahuila", "tamaulipas"],
    "oaxaca": ["guerrero", "puebla", "veracruz", "chiapas"],
    "puebla": ["tlaxcala", "estado_de_mexico", "morelos", "guerrero", "oaxaca", "veracruz", "hidalgo"],
    "queretaro": ["guanajuato", "hidalgo", "México"],
    "quintana_roo": ["Yucatán", "campeche"],
    "san_luis_potosi": ["guanajuato", "zacatecas", "jalisco", "nayarit", "hidalgo", "veracruz", "tamaulipas"],
    "sinaloa": ["sonora", "chihuahua", "durango", "nayarit"],
    "sonora": ["baja_california", "chihuahua", "sinaloa"],
    "tabasco": ["veracruz", "chiapas", "campeche"],
    "tamaulipas": ["nuevo_leon", "coahuila", "san_luis_potosi", "veracruz"],
    "tlaxcala": ["puebla", "estado_de_mexico", "hidalgo"],
    "veracruz": ["tamaulipas", "san_luis_potosi", "hidalgo", "puebla", "oaxaca", "tabasco", "chiapas"],
    "yucatan": ["quintana_roo", "campeche"],
    "zacatecas": ["durango", "zacatecas", "jalisco", "aguascalientes", "san_luis_potosi", "guanajuato"],
  };

  export default function findShortestPath(origin, destination) {
    const visited = new Set();
    const distances = {};
    const previous = {};
    let shortestPath = [];
  
    const normalizeState = (state) =>
      state.toLowerCase().trim().replace(/ /g, "_").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  
    origin = normalizeState(origin);
    destination = normalizeState(destination);
  
    for (const state in limitStates) {
      distances[state] = Infinity;
      previous[state] = null;
    }
  
    distances[origin] = 0;
  
    while (true) {
      let closestState = null;
      let shortestDistance = Infinity;
  
      for (const state in distances) {
        if (!visited.has(state) && distances[state] < shortestDistance) {
          closestState = state;
          shortestDistance = distances[state];
        }
      }
  
      if (closestState === null || closestState === destination) {
        break;
      }
  
      visited.add(closestState);
  
      for (const neighbor of limitStates[closestState]) {
        const distance = distances[closestState] + 1; // Assuming all edges have weight 1
        if (distance < distances[neighbor]) {
          distances[neighbor] = distance;
          previous[neighbor] = closestState;
        }
      }
    }
  
    if (previous[destination] !== null || destination === origin) {
      let currentState = destination;
      while (currentState !== null) {
        shortestPath.unshift(currentState);
        currentState = previous[currentState];
      }
    }
  
    return shortestPath.length > 0 ? shortestPath : null;
  }
