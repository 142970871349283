import { cleanRouteInformation, cleanTripInformation, setRouteInformation, setTripInformation } from "./"

export const startSetRouteInformation = (route) => {
    return ( dispatch ) => {
        dispatch( setRouteInformation(route) )
    }
}

export const startSetTripInformation = (trip) => {
    return ( dispatch ) => {
        dispatch( setTripInformation(trip) )
    }
}

export const startCleanTripInformation = () => {
    return ( dispatch ) => {
        dispatch( cleanTripInformation() )
        dispatch( cleanRouteInformation() )
    }
}
