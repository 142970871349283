import { useState } from "react";
import { Button, Inputs } from "../atoms";
import { PasswordValidation } from "../validations";
import { useNavigate } from "react-router-dom";
const initialValues = {
  password: "",
  confirmPassword: "",
};

export const RestorePassword = () => {
  const [newPassword, setNewPassword] = useState(initialValues);
  const [alerts, setAlerts] = useState(initialValues);

  const { password, confirmPassword } = newPassword;

  const handleChange = (e) => {
    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const alerts = {
      password: PasswordValidation(password),
      confirmPassword: PasswordValidation(confirmPassword),
    };
    setAlerts(alerts);
  };
    const navigate = useNavigate();
    const nextPage = () => {
      navigate("/login");
    };
    const prevPage = () => {
      navigate(-1);
    };

  return (
    <>
      <div className="flex flex-col justify-evenly h-[90vh] p-10">
        <div className="grid gap-2 mb-2">
          <Inputs
            type="password"
            id="password"
            name="password"
            placeholder="Contraseña"
            value={password}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-4 py-2">
            {alerts.password}
          </div>

          <Inputs
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirmar contraseña"
            value={confirmPassword}
            onChange={handleChange}
          />
          <div className="text-red-500 font-bold px-4 py-2">
            {alerts.confirmPassword}
          </div>
        </div>
      </div>
      <div className="flex justify-evenly gap-10">
        <Button text="ATRAS" type="button" onClick={prevPage} />

        <Button text="SIGUIENTE" type="submit" onClick={nextPage} />
      </div>
    </>
  );
};
