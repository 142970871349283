import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../molecules";
import { Button, Inputs } from "../atoms";
import { startSetTripInformation } from "../store/trip";

export const Trip = () => {
  const [tripInfo, setTripInfo] = useState({ origin: "", destination: "" });
  const { origin, destination } = tripInfo;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setTripInfo({
      ...tripInfo,
      [e.target.name]: e.target.value,
    });
  };

  const initTrip = () => {
    if (origin.length > 5 && destination.length > 5) {
      dispatch(startSetTripInformation(tripInfo));
      navigate("/map");
    }
  };
  const prevPage = () => {
    navigate(-1);
  };
  return (
    <>
      <Navbar title={"Viaje"} display={true} />

      <div className="flex flex-col p-10">
        <div className="h-[65vh] flex items-center">
          <div className="grid gap-10">
            <div>
              <Inputs
                type="text"
                id="origin"
                name="origin"
                placeholder="Origen"
                value={origin}
                onChange={handleChange}
              />
            </div>
            <div>
              <Inputs
                type="text"
                id="destination"
                name="destination"
                placeholder="Destino"
                value={destination}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-evenly">
          <Button
            className=""
            text="Iniciar Viaje"
            type="button"
            onClick={initTrip}
          />
        </div>
      </div>
    </>
  );
};
