import { configureStore } from '@reduxjs/toolkit'
import { tripSlice } from './trip'
import { authSlice } from './auth'
// import { journalSlice } from './journal'

export const store = configureStore({
    reducer: {
        trip: tripSlice.reducer,
        auth: authSlice.reducer,
    }
})